export const onlineAndBlogs = [];
for (let i = 0; i < 15; i++) {
    onlineAndBlogs.push({
        id: i,
        blogId: 'B1254',
        blogName: 'Population of Canada',
        related: 'Speaking',
        medium: 'APICAL Portal',
        createdAt: '12 Jun, 2019, 4:50pm',
        createdBy: 'Kazi Shahin',
   })
}

export const trialClassStudent = [];
for (let i = 0; i < 15; i++) {
    trialClassStudent.push({
        id: i,
        stdId: 'B1254',
        studentName: 'Cristiano Ronaldo',
        course: 'EXP. Listening',
        branch: 'Dhanmondi',
        duration: '3 Days',
        admittedAt: '12 Jun, 2019, 4:50pm',
        admittedBy: 'Kazi Shahin',
   })
}

export const expiredStudents = [];
for (let i = 0; i < 15; i++) {
    expiredStudents.push({
        id: i,
        stdId: 'B1254',
        studentName: 'Cristiano Ronaldo',
        course: 'EXP. Listening',
        branch: 'Dhanmondi',
        startedFrom: '12 Jun, 2019',
        endsAt: '12 Jun, 2020',
        admittedBy: 'Kazi Shahin',
   })
}


export const seminars = [];
for (let i = 0; i < 15; i++) {
    seminars.push({
        id: i,
        seminarId: 'B1254',
        seminarName: 'Intermidiate of PTI',
        course: 'EXP. Listening',
        branch: 'Dhanmondi',
        group: 'GROUP B',
        date: '12 Jun, 2020',
        venue: 'Dhanmondi head office',
        createdBy: 'Kazi Shahin',
   })
}

export const webinars = [];
for (let i = 0; i < 15; i++) {
    webinars.push({
        id: i,
        webinarId: 'B1254',
        webinarName: 'Intermidiate of PTI',
        course: 'EXP. Listening',
        branch: 'Dhanmondi',
        group: 'GROUP B',
        date: '12 Jun, 2020',
        venue: 'Dhanmondi head office',
        createdBy: 'Kazi Shahin',
   })
}


export const workshop = [];
for (let i = 0; i < 15; i++) {
    workshop.push({
        id: i,
        workshopId: 'B1254',
        workshopName: 'Intermidiate of PTI',
        course: 'EXP. Listening',
        branch: 'Dhanmondi',
        group: 'GROUP B',
        date: '12 Jun, 2020',
        venue: 'Dhanmondi head office',
        createdBy: 'Kazi Shahin',
   })
}


